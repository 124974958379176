import { render, staticRenderFns } from "./WhitebookView.vue?vue&type=template&id=8fdbc162&scoped=true&"
import script from "./WhitebookView.vue?vue&type=script&lang=js&"
export * from "./WhitebookView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fdbc162",
  null
  
)

export default component.exports