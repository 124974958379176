<template>
  <div class="account" style="padding-bottom: 100px">
    <top-fixed></top-fixed>

    <!--background-color: #1d76fb;  -->
    <div style="height: 90%">
      <iframe
        src="/static/white-paper.html"
        frameborder="0"
        style="width: 100%; height: 100%"
      ></iframe>
    </div>
  </div>
</template>
  

  <script>
import TopFixed from "../components/TopFixed.vue";
export default {
  name: "AccountView",
  data () {
    return {
      index: 0,
      iframeUrl: "/static/white-paper.html"
    }
  },
  components: { TopFixed },
};
  </script>
<style scoped>
</style>
  